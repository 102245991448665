import { render, staticRenderFns } from "./Dialogs.vue?vue&type=template&id=929c58be&"
import script from "./Dialogs.vue?vue&type=script&lang=js&"
export * from "./Dialogs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiShare: require('/codebuild/output/src2545663173/src/med-el-hearpeers/components/Ui/Share.vue').default,UiDialog: require('/codebuild/output/src2545663173/src/med-el-hearpeers/components/Ui/Dialog/index.vue').default,FormNewsletter: require('/codebuild/output/src2545663173/src/med-el-hearpeers/components/Form/Newsletter.vue').default,FormHearingDiary: require('/codebuild/output/src2545663173/src/med-el-hearpeers/components/Form/HearingDiary.vue').default,FormContactMentorEnAu: require('/codebuild/output/src2545663173/src/med-el-hearpeers/components/Form/ContactMentorEnAu/index.vue').default,FormContactMentorEnGb: require('/codebuild/output/src2545663173/src/med-el-hearpeers/components/Form/ContactMentorEnGb/index.vue').default,FormContactMentorDe: require('/codebuild/output/src2545663173/src/med-el-hearpeers/components/Form/ContactMentorDe/index.vue').default,FormContactMentorPl: require('/codebuild/output/src2545663173/src/med-el-hearpeers/components/Form/ContactMentorPl/index.vue').default,FormContactMentorFr: require('/codebuild/output/src2545663173/src/med-el-hearpeers/components/Form/ContactMentorFr/index.vue').default,FormContactMentorNl: require('/codebuild/output/src2545663173/src/med-el-hearpeers/components/Form/ContactMentorNl/index.vue').default,FormContactMentor: require('/codebuild/output/src2545663173/src/med-el-hearpeers/components/Form/ContactMentor/index.vue').default,AvatarTool: require('/codebuild/output/src2545663173/src/med-el-hearpeers/components/AvatarTool/index.vue').default})
